import React from 'react';
import { Helmet } from 'react-helmet';
import AppRoutes from './routes';

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#1E40AF" />
        <link rel="icon" href="/favicon.ico" />

        {/* Métadonnées SEO par défaut */}
        <title>QADIR Experts - Solutions pour le développement territorial</title>
        <meta
          name="description"
          content="QADIR Experts fournit des solutions innovantes en urbanisme, géomatique, et développement territorial durable."
        />

        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="QADIR Experts" />
        <meta
          property="og:description"
          content="Solutions innovantes pour le développement territorial"
        />
        <meta property="og:image" content="/og-image.jpg" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="QADIR Experts" />
        <meta
          name="twitter:description"
          content="Solutions innovantes pour le développement territorial"
        />
        <meta name="twitter:image" content="/twitter-image.jpg" />
      </Helmet>

      <AppRoutes />
    </>
  );
};

export default App;
