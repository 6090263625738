import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MapPin,
  Phone,
  Mail,
  ArrowRight,
  Map,
  BarChart,
  Building,
  Laptop,
  ExternalLink,
  ChevronRight,
  ArrowUpRight,
  Users,
  Target,
  Award,
  Rocket,
  CheckCircle,
  Share2,
} from 'lucide-react';
import Navigation from '@components/Navigation';
import Footer from '@components/Footer';

export default function HomePage() {
  const [activeService, setActiveService] = useState(0);

  const services = [
    {
      icon: <Map />,
      title: 'Urbanisme & Aménagement',
      description:
        'Diagnostic territorial, planification stratégique et aménagement des espaces publics',
      color: 'from-blue-600 to-blue-400',
      link: '/services/urbanisme',
    },
    {
      icon: <BarChart />,
      title: 'Géomatique & Intelligence Territoriale',
      description: "Cartographie, analyses spatiales et développement d'infrastructures de données",
      color: 'from-purple-600 to-purple-400',
      link: '/services/geomatique',
    },
    {
      icon: <Building />,
      title: 'Foncier & Immobilier',
      description: 'Sécurisation foncière, gestion des titres fonciers et évaluation immobilière',
      color: 'from-orange-600 to-orange-400',
      link: '/services/foncier',
    },
    {
      icon: <Laptop />,
      title: 'Solutions Numériques',
      description: "Développement d'applications, gestion de données en temps réel",
      color: 'from-green-600 to-green-400',
      link: '/services/numerique',
    },
  ];

  const stats = [
    { number: '05+', label: "Ans d'expérience" },
    { number: '50+', label: 'Projets réalisés' },
    { number: '20+', label: 'Experts qualifiés' },
    { number: '95%', label: 'Clients satisfaits' },
  ];

  const values = [
    {
      icon: <Award className="w-8 h-8 text-blue-600" />,
      title: 'Excellence',
      description: "Nous visons l'excellence dans chaque projet et chaque interaction.",
    },
    {
      icon: <Target className="w-8 h-8 text-orange-600" />,
      title: 'Innovation',
      description: 'Adoption des dernières technologies pour des solutions optimales.',
    },
    {
      icon: <Users className="w-8 h-8 text-green-600" />,
      title: 'Collaboration',
      description: "Travail d'équipe étroit avec nos clients et partenaires.",
    },
    {
      icon: <Rocket className="w-8 h-8 text-purple-600" />,
      title: 'Performance',
      description: 'Résultats mesurables et impact positif sur les territoires.',
    },
  ];

  const features = [
    'Solutions innovantes pour le développement territorial',
    'Expertise technique de haut niveau',
    'Approche durable et responsable',
    "Engagement pour l'excellence",
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navigation />

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-background"></div>
        <div className="hero-overlay"></div>
        <div className="relative container mx-auto px-4 py-32">
          <div className="max-w-3xl">
            <div className="inline-flex items-center space-x-2 bg-blue-800/50 px-4 py-2 rounded-full text-blue-200 mb-6">
              <span className="relative flex h-2 w-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-300 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-400"></span>
              </span>
              <span>Innovation et expertise à votre service</span>
            </div>

            <h1 className="text-6xl font-bold text-white mb-8 leading-tight">
              Transformons ensemble votre vision territoriale en{' '}
              <span className="text-gradient">solutions concrètes</span>
            </h1>

            <p className="text-xl text-blue-100 mb-12 max-w-2xl">
              Expertise en urbanisme, géomatique et sécurisation foncière pour un développement
              territorial durable
            </p>

            <div className="flex space-x-6">
              <Link
                to="/services"
                className="bg-white text-blue-900 px-8 py-4 rounded-full font-medium hover:bg-gray-100 transition-colors flex items-center group"
              >
                Découvrir nos services
                <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link
                to="/contact"
                className="border-2 border-white text-white px-8 py-4 rounded-full font-medium hover:bg-white/10 transition-colors"
              >
                Nous contacter
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-32 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-20">
            <h2 className="text-4xl font-bold mb-6">Nos Services</h2>
            <p className="text-gray-600">
              Des solutions innovantes et sur mesure pour répondre à vos besoins en matière de
              développement territorial
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <Link
                key={index}
                to={service.link}
                className={`bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 ${
                  activeService === index ? 'ring-2 ring-blue-500' : ''
                }`}
                onMouseEnter={() => setActiveService(index)}
              >
                <div className={`w-16 h-16 rounded-xl bg-gradient-to-r ${service.color} p-4 mb-6`}>
                  <div className="text-white w-full h-full">{service.icon}</div>
                </div>
                <h3 className="text-xl font-bold mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <div className="text-blue-600 font-medium flex items-center group">
                  En savoir plus
                  <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Statistiques Section */}
      <section className="py-20 bg-blue-900 text-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
            {stats.map((stat, index) => (
              <div key={index} className="p-6">
                <div className="text-4xl font-bold mb-2">{stat.number}</div>
                <div className="text-blue-200">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* À Propos Section */}
      <section className="py-32">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <div className="inline-flex items-center space-x-2 bg-blue-100 px-4 py-2 rounded-full text-blue-700 mb-6">
                <span>À propos de nous</span>
              </div>
              <h2 className="text-4xl font-bold mb-8">
                Une expertise reconnue au service de votre territoire
              </h2>
              <p className="text-gray-600 mb-8 text-lg">
                QADIR Experts est une entreprise interdisciplinaire spécialisée dans l'urbanisme, la
                géomatique, et le foncier, avec une approche tournée vers l'innovation numérique.
              </p>
              <div className="space-y-4 mb-8">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-center">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                    <span className="text-gray-700">{feature}</span>
                  </div>
                ))}
              </div>
              <Link
                to="/about"
                className="bg-blue-900 text-white px-8 py-4 rounded-full font-medium hover:bg-blue-800 transition-colors flex items-center group inline-flex"
              >
                En savoir plus
                <ExternalLink className="ml-2 w-5 h-5 group-hover:rotate-45 transition-transform" />
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <div className="bg-blue-50 p-6 rounded-xl">
                  <h4 className="font-bold mb-2">Innovation</h4>
                  <p className="text-gray-600">Intégration des dernières technologies</p>
                </div>
                <div className="bg-blue-900 text-white p-6 rounded-xl">
                  <h4 className="font-bold mb-2">Engagement</h4>
                  <p className="text-blue-100">Implication totale dans chaque projet</p>
                </div>
              </div>
              <div className="space-y-4 pt-8">
                <div className="bg-orange-50 p-6 rounded-xl">
                  <h4 className="font-bold mb-2">Qualité</h4>
                  <p className="text-gray-600">Excellence dans nos services</p>
                </div>
                <div className="bg-green-50 p-6 rounded-xl">
                  <h4 className="font-bold mb-2">Durabilité</h4>
                  <p className="text-gray-600">Solutions respectueuses de l'environnement</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Valeurs Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Nos Valeurs</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="mb-4">{value.icon}</div>
                <h3 className="text-xl font-bold mb-3">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact CTA Section */}
      <section className="bg-gradient-to-r from-blue-900 to-blue-800 text-white py-32">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-8">
              Prêt à transformer votre projet en réalité ?
            </h2>
            <p className="text-xl text-blue-100 mb-12">
              Contactez-nous dès aujourd'hui pour discuter de vos besoins et découvrir comment nous
              pouvons vous aider.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
              <ContactCard
                icon={<MapPin className="w-6 h-6" />}
                title="Notre adresse"
                content="Abidjan, Côte d'Ivoire"
              />
              <ContactCard
                icon={<Phone className="w-6 h-6" />}
                title="Téléphone"
                content="+225 01 43 43 05 05"
              />
              <ContactCard
                icon={<Mail className="w-6 h-6" />}
                title="Email"
                content="contact@qadir-experts.com"
              />
            </div>
            <Link
              to="/contact"
              className="bg-white text-blue-900 px-8 py-4 rounded-full font-medium hover:bg-gray-100 transition-colors inline-flex items-center group"
            >
              Contactez-nous maintenant
              <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

const ContactCard = ({ icon, title, content }) => (
  <div className="bg-blue-800/50 p-6 rounded-xl hover:bg-blue-800 transition-colors">
    <div className="bg-blue-700/50 w-12 h-12 rounded-full flex items-center justify-center mb-4 mx-auto">
      {icon}
    </div>
    <h3 className="font-bold mb-2">{title}</h3>
    <p className="text-blue-100">{content}</p>
  </div>
);
