import React from 'react';
import { Map, BarChart, Building, Laptop, ArrowUpRight, Check } from 'lucide-react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default function ServicesPage() {
  const mainServices = [
    {
      icon: <Map />,
      title: 'Urbanisme & Aménagement',
      description: "Solutions complètes pour l'aménagement urbain et territorial",
      features: [
        'Diagnostic territorial approfondi',
        'Planification stratégique',
        "Conception d'espaces publics",
        'Gestion des risques',
        'Revitalisation urbaine',
        'Opérations immobilières',
      ],
      color: 'from-blue-600 to-blue-400',
      link: '/services/urbanisme',
    },
    {
      icon: <BarChart />,
      title: 'Géomatique & Intelligence Territoriale',
      description: 'Expertise en cartographie et analyse spatiale',
      features: [
        'Cartographie et SIG',
        'Télédétection & imagerie satellite',
        'Analyse spatiale & modélisation 3D',
        'Infrastructure de données géographiques',
        'Formation en SIG',
        'Géo-intelligence et Big Data',
      ],
      color: 'from-purple-600 to-purple-400',
      link: '/services/geomatique',
    },
    {
      icon: <Building />,
      title: 'Foncier & Immobilier',
      description: 'Services complets de gestion foncière et immobilière',
      features: [
        'Analyse et diagnostic foncier',
        'Gestion foncière',
        'Conseil en acquisition',
        'Développement immobilier',
        'Évaluations et expertises',
        'Consulting en investissement',
      ],
      color: 'from-orange-600 to-orange-400',
      link: '/services/foncier',
    },
    {
      icon: <Laptop />,
      title: 'Solutions Numériques',
      description: 'Innovation technologique pour vos projets',
      features: [
        "Développement d'applications",
        'Automatisation des processus',
        'Solutions de visualisation',
        "Systèmes d'information sur mesure",
        'Solutions Big Data',
        'Formation et support',
      ],
      color: 'from-green-600 to-green-400',
      link: '/services/numerique',
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="relative bg-blue-900 py-20 text-white overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-800 to-blue-900 opacity-90"></div>
        <div className="image-placeholder w-full h-full"></div>
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl">
            <div className="inline-flex items-center space-x-2 bg-blue-800/50 px-4 py-2 rounded-full text-blue-200 mb-6">
              <span>Nos expertises</span>
            </div>
            <h1 className="text-5xl font-bold mb-6">Des Solutions Sur Mesure pour Vos Projets</h1>
            <p className="text-xl text-blue-100">
              Une approche intégrée combinant expertise technique et innovation pour répondre à vos
              besoins spécifiques
            </p>
          </div>
        </div>
      </section>

      {/* Services Grid - Version Moderne */}
      <section className="py-20 -mt-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {mainServices.map((service, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                {/* Header */}
                <div className="flex items-start gap-6 mb-8">
                  <div
                    className={`w-16 h-16 rounded-xl bg-gradient-to-r ${service.color} p-4 flex-shrink-0`}
                  >
                    <div className="text-white w-full h-full">{service.icon}</div>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold mb-2">{service.title}</h2>
                    <p className="text-gray-600">{service.description}</p>
                  </div>
                </div>

                {/* Features */}
                <div className="space-y-4 mb-8">
                  {service.features.map((feature, idx) => (
                    <div key={idx} className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-3" />
                      <span className="text-gray-700">{feature}</span>
                    </div>
                  ))}
                </div>

                {/* CTA */}
                <a
                  href={service.link}
                  className="inline-flex items-center text-blue-600 font-medium hover:text-blue-800 transition-colors group"
                >
                  En savoir plus
                  <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Approche Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="text-3xl font-bold mb-6">Notre Approche</h2>
            <p className="text-gray-600">
              Une méthodologie éprouvée pour garantir le succès de vos projets
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                number: '01',
                title: 'Analyse',
                description: 'Étude approfondie de vos besoins et objectifs',
              },
              {
                number: '02',
                title: 'Conception',
                description: 'Élaboration de solutions personnalisées',
              },
              {
                number: '03',
                title: 'Réalisation',
                description: 'Mise en œuvre experte et suivi rigoureux',
              },
              {
                number: '04',
                title: 'Support',
                description: 'Accompagnement continu et optimisation',
              },
            ].map((step, index) => (
              <div key={index} className="relative group">
                <div className="bg-gray-50 rounded-xl p-6 h-full transition-all duration-300 group-hover:bg-blue-900 group-hover:text-white">
                  <div className="text-5xl font-bold text-blue-200 mb-4 group-hover:text-blue-100">
                    {step.number}
                  </div>
                  <h3 className="text-xl font-bold mb-3">{step.title}</h3>
                  <p className="text-gray-600 group-hover:text-blue-100">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-blue-900 to-blue-800 py-20 text-white">
        <div className="container mx-auto px-4 text-center">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold mb-6">Prêt à lancer votre projet ?</h2>
            <p className="text-xl text-blue-100 mb-8">
              Nos experts sont à votre disposition pour étudier vos besoins et vous proposer les
              meilleures solutions.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a
                href="/contact"
                className="inline-flex items-center bg-white text-blue-900 px-8 py-4 rounded-full font-medium hover:bg-gray-100 transition-colors"
              >
                Parlons de votre projet
                <ArrowUpRight className="ml-2 w-5 h-5" />
              </a>
              <a
                href="/about"
                className="inline-flex items-center border-2 border-white text-white px-8 py-4 rounded-full font-medium hover:bg-white/10 transition-colors"
              >
                Découvrir notre équipe
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
