import React, { useState } from 'react';
import { MapPin, Phone, Mail, Clock, Send, Check } from 'lucide-react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    // Simuler l'envoi du formulaire
    setTimeout(() => {
      setIsSubmitting(false);
      setSubmitSuccess(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });
      setTimeout(() => setSubmitSuccess(false), 5000);
    }, 1500);
  };

  const contactInfo = [
    {
      icon: <MapPin />,
      title: 'Notre Adresse',
      content: "Abidjan, Côte d'Ivoire",
      link: 'https://maps.google.com',
    },
    {
      icon: <Phone />,
      title: 'Téléphone',
      content: '+225 01 43 43 05 05',
      link: 'tel:+2250143430505',
    },
    {
      icon: <Mail />,
      title: 'Email',
      content: 'contact@qadir-experts.com',
      link: 'mailto:contact@qadir-experts.com',
    },
    {
      icon: <Clock />,
      title: 'Horaires',
      content: 'Lundi - Vendredi : 09h - 18h',
      link: null,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="bg-blue-900 py-20 text-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-bold mb-6">Contactez-nous</h1>
            <p className="text-xl text-blue-100">
              Notre équipe est à votre écoute pour répondre à vos questions et vous accompagner dans
              vos projets
            </p>
          </div>
        </div>
      </section>

      {/* Contact Info Cards */}
      <section className="py-12 -mt-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {contactInfo.map((info, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg p-6">
                <div className="flex items-start space-x-4">
                  <div className="bg-blue-100 p-3 rounded-lg text-blue-600">{info.icon}</div>
                  <div>
                    <h3 className="font-bold text-lg mb-1">{info.title}</h3>
                    {info.link ? (
                      <a
                        href={info.link}
                        className="text-gray-600 hover:text-blue-600 transition-colors"
                      >
                        {info.content}
                      </a>
                    ) : (
                      <p className="text-gray-600">{info.content}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Form & Map Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
            {/* Contact Form */}
            <div className="bg-white rounded-2xl shadow-lg p-8">
              <h2 className="text-3xl font-bold mb-6">Envoyez-nous un message</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-gray-700 font-medium mb-2">Nom complet</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 font-medium mb-2">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                      required
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-gray-700 font-medium mb-2">Téléphone</label>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 font-medium mb-2">Sujet</label>
                    <select
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                      required
                    >
                      <option value="">Sélectionnez un sujet</option>
                      <option value="urbanisme">Urbanisme & Aménagement</option>
                      <option value="geomatique">Géomatique & Intelligence Territoriale</option>
                      <option value="foncier">Foncier & Immobilier</option>
                      <option value="numerique">Solutions Numériques</option>
                      <option value="autre">Autre</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label className="block text-gray-700 font-medium mb-2">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="6"
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full bg-blue-900 text-white px-8 py-4 rounded-lg font-medium 
                    hover:bg-blue-800 transition-colors flex items-center justify-center space-x-2
                    ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
                >
                  {isSubmitting ? (
                    <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></span>
                  ) : (
                    <>
                      <Send className="w-5 h-5" />
                      <span>Envoyer le message</span>
                    </>
                  )}
                </button>
                {submitSuccess && (
                  <div className="bg-green-100 text-green-700 px-4 py-3 rounded-lg">
                    Message envoyé avec succès ! Nous vous répondrons dans les plus brefs délais.
                  </div>
                )}
              </form>
            </div>

            {/* Map & Additional Info */}
            <div className="space-y-8">
              <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
                <div className="aspect-w-16 aspect-h-9">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d696.5050504436117!2d-3.9777500080375123!3d5.414201241939034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc19309f13aea0b%3A0x5c8c894847339c7d!2sAngr%C3%A9%20Star%2016!5e0!3m2!1sfr!2sci!4v1732130307314!5m2!1sfr!2sci"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="rounded-none"
                  ></iframe>
                </div>
              </div>
              <div className="bg-blue-900 text-white rounded-2xl p-8">
                <h3 className="text-2xl font-bold mb-4">Pourquoi nous contacter ?</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="bg-blue-800 p-1 rounded-full mr-3 mt-1">
                      <Check className="w-4 h-4" />
                    </span>
                    <span>Expertise reconnue en développement territorial</span>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-800 p-1 rounded-full mr-3 mt-1">
                      <Check className="w-4 h-4" />
                    </span>
                    <span>Solutions personnalisées adaptées à vos besoins</span>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-800 p-1 rounded-full mr-3 mt-1">
                      <Check className="w-4 h-4" />
                    </span>
                    <span>Accompagnement complet de votre projet</span>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-800 p-1 rounded-full mr-3 mt-1">
                      <Check className="w-4 h-4" />
                    </span>
                    <span>Innovation technologique au service de vos objectifs</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
