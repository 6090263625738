import React from 'react';
import {
  Laptop,
  Monitor,
  Database,
  Cloud,
  Code,
  Smartphone,
  Target,
  CheckCircle,
  ArrowUpRight,
  Users,
  File,
  ArrowRight,
  Settings,
  Wifi,
  Lock,
} from 'lucide-react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

export default function SolutionsNumeriques() {
  const service = {
    id: 'numerique',
    title: 'Solutions Numériques & Innovations',
    description:
      "Développement de solutions digitales innovantes pour la gestion et l'optimisation de vos projets territoriaux",
    icon: <Laptop className="w-12 h-12 text-white" />,
    color: 'from-emerald-600 to-emerald-400',
    features: [
      'Applications web personnalisées',
      'Systèmes de gestion géospatiale',
      'Tableaux de bord interactifs',
      'APIs et intégrations',
      'Solutions mobiles',
      'Automatisation des processus',
    ],
    process: [
      {
        title: 'Analyse des besoins',
        description: 'Compréhension détaillée de vos objectifs et contraintes',
      },
      {
        title: 'Architecture',
        description: "Conception de l'architecture technique adaptée",
      },
      {
        title: 'Développement',
        description: 'Création des solutions sur mesure',
      },
      {
        title: 'Déploiement',
        description: 'Mise en production et support continu',
      },
    ],
    technologies: [
      {
        icon: <Monitor />,
        title: 'Applications Web',
        description: 'Interfaces modernes et intuitives',
      },
      {
        icon: <Database />,
        title: 'Gestion de Données',
        description: 'Stockage et traitement sécurisé',
      },
      {
        icon: <Cloud />,
        title: 'Cloud Computing',
        description: 'Solutions scalables et performantes',
      },
    ],
    projects: [
      {
        title: 'Plateforme de Gestion Territoriale',
        description: 'Solution intégrée pour la gestion des données urbaines',
        image: <div className="image-placeholder aspect-w-4 aspect-h-3 rounded-lg"></div>,
        tech: ['React', 'Node.js', 'PostgreSQL', 'Docker'],
        features: [
          'Dashboard temps réel',
          'Cartographie interactive',
          'Gestion de données massives',
        ],
      },
      {
        title: 'Application Mobile de Collecte',
        description: 'Solution mobile pour la collecte de données sur le terrain',
        image: <div className="image-placeholder aspect-w-4 aspect-h-3 rounded-lg"></div>,
        tech: ['React Native', 'GraphQL', 'AWS'],
        features: ['Mode hors ligne', 'Géolocalisation', 'Synchronisation automatique'],
      },
    ],
    benefits: [
      {
        icon: <Settings />,
        title: 'Automatisation',
        description: 'Optimisation des processus métier',
      },
      {
        icon: <Wifi />,
        title: 'Connectivité',
        description: 'Solutions accessibles partout',
      },
      {
        icon: <Lock />,
        title: 'Sécurité',
        description: 'Protection des données garantie',
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="relative bg-emerald-900 py-20 text-white">
        <div className="absolute inset-0 bg-gradient-to-r from-emerald-900 via-emerald-800 to-emerald-900 opacity-90"></div>
        <div className="image-placeholder w-full h-full"></div>
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl">
            <div className={`w-20 h-20 rounded-2xl bg-gradient-to-r ${service.color} p-4 mb-8`}>
              {service.icon}
            </div>
            <h1 className="text-5xl font-bold mb-6">{service.title}</h1>
            <p className="text-xl text-emerald-100">{service.description}</p>
          </div>
        </div>
      </section>

      {/* Technologies Section */}
      <section className="py-20 -mt-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {service.technologies.map((tech, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className={`w-16 h-16 rounded-xl bg-gradient-to-r ${service.color} p-4 mb-6`}>
                  <div className="text-white w-full h-full">{tech.icon}</div>
                </div>
                <h3 className="text-xl font-bold mb-3">{tech.title}</h3>
                <p className="text-gray-600">{tech.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-16">
            <div className="lg:col-span-2">
              <h2 className="text-3xl font-bold mb-8">Nos Solutions</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {service.features.map((feature, index) => (
                  <div key={index} className="flex items-start bg-white p-6 rounded-xl shadow-lg">
                    <CheckCircle className="w-6 h-6 text-emerald-500 mr-4 flex-shrink-0" />
                    <span className="text-gray-700">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-bold mb-6">Nos avantages</h3>
              <div className="space-y-6">
                {service.benefits.map((benefit, index) => (
                  <div key={index} className="flex items-center">
                    <div className="bg-emerald-100 p-3 rounded-full mr-4">
                      {React.cloneElement(benefit.icon, {
                        className: 'w-6 h-6 text-emerald-600',
                      })}
                    </div>
                    <div>
                      <h4 className="font-bold">{benefit.title}</h4>
                      <p className="text-gray-600">{benefit.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Notre processus de développement</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {service.process.map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-emerald-100 rounded-xl p-6">
                  <span className="absolute -top-4 left-4 bg-emerald-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
                    {index + 1}
                  </span>
                  <h3 className="font-bold text-xl mb-3 mt-2">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                {index < service.process.length - 1 && (
                  <ArrowRight className="hidden lg:block absolute top-1/2 -right-6 w-6 h-6 text-emerald-300 transform -translate-y-1/2" />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12">Projets réalisés</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {service.projects.map((project, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden group">
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <div className="mb-4">
                    <h4 className="font-semibold mb-2">Technologies utilisées :</h4>
                    <div className="flex flex-wrap gap-2">
                      {project.tech.map((tech, techIndex) => (
                        <span
                          key={techIndex}
                          className="bg-emerald-100 text-emerald-600 px-3 py-1 rounded-full text-sm"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="space-y-2">
                    {project.features.map((feature, featureIndex) => (
                      <div key={featureIndex} className="flex items-center text-gray-600">
                        <CheckCircle className="w-4 h-4 mr-2 text-emerald-500" />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div>
                  <button className="mt-4 text-emerald-600 font-medium flex items-center group">
                    Voir le détail
                    <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="bg-gradient-to-r from-emerald-900 to-emerald-800 py-20 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Prêt à digitaliser vos processus ?</h2>
          <p className="text-xl text-emerald-100 mb-8 max-w-2xl mx-auto">
            Discutons de vos besoins et trouvons ensemble la solution numérique adaptée à vos
            objectifs.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="/contact"
              className="inline-flex items-center bg-white text-emerald-900 px-8 py-4 rounded-full font-medium hover:bg-gray-100 transition-colors"
            >
              Demander un devis
              <ArrowUpRight className="ml-2 w-5 h-5" />
            </a>
            <a
              href="#"
              className="inline-flex items-center border-2 border-white text-white px-8 py-4 rounded-full font-medium hover:bg-white/10 transition-colors"
            >
              <File className="w-5 h-5 mr-2" />
              Documentation technique
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
