import React from 'react';
import {
  Calendar,
  User,
  Clock,
  Share2,
  Facebook,
  Twitter,
  Linkedin,
  ArrowLeft,
  ArrowRight,
} from 'lucide-react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default function BlogPost() {
  // Simulation des données d'un article
  const article = {
    id: 1,
    title: "L'importance de la géomatique dans l'aménagement urbain moderne",
    category: 'Géomatique',
    date: '2024-03-15',
    author: 'Jean Dupont',
    readTime: '5 min',
    image: <div className="image-placeholder aspect-w-2 aspect-h-1 rounded-lg"></div>,
    content: `
      <div class="prose prose-lg mx-auto">
        <p class="lead">
          La géomatique joue un rôle crucial dans la planification et l'aménagement urbain moderne. 
          Les technologies géospatiales permettent aujourd'hui une analyse plus précise et une 
          meilleure prise de décision dans les projets d'urbanisme.
        </p>

        <h2>L'évolution des technologies géospatiales</h2>
        <p>
          Les avancées technologiques des dernières années ont transformé la manière dont nous 
          appréhendons l'espace urbain. Les systèmes d'information géographique (SIG), la 
          télédétection et la modélisation 3D sont devenus des outils indispensables.
        </p>

        <h2>Applications concrètes</h2>
        <p>
          De la gestion des infrastructures à l'analyse des flux urbains, la géomatique offre 
          des solutions innovantes pour répondre aux défis de l'urbanisation croissante.
        </p>

        <h3>1. Planification urbaine</h3>
        <p>
          L'utilisation des SIG permet une meilleure compréhension des dynamiques urbaines 
          et facilite la prise de décision en matière d'aménagement.
        </p>

        <h3>2. Gestion des risques</h3>
        <p>
          La modélisation spatiale aide à identifier et à prévenir les risques naturels 
          et anthropiques dans les zones urbaines.
        </p>
      </div>
    `,
  };

  // Articles recommandés
  const recommendedPosts = [
    {
      id: 2,
      title: "Smart Cities : l'avenir de l'urbanisme intelligent",
      image: <div className="placeholder-image aspect-w-4 aspect-h-3"></div>,
      excerpt: 'Découvrez comment les villes intelligentes transforment notre façon de vivre...',
      category: 'Urbanisme',
    },
    {
      id: 3,
      title: 'Innovation numérique dans la gestion territoriale',
      image: <div className="placeholder-image aspect-w-4 aspect-h-3"></div>,
      excerpt: 'Les nouvelles technologies au service de la gestion des territoires...',
      category: 'Numérique',
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Article Header */}
      <header className="relative bg-blue-900 py-20 text-white">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="mb-6">
              <span className="bg-blue-800 text-blue-100 px-4 py-2 rounded-full text-sm font-medium">
                {article.category}
              </span>
            </div>
            <h1 className="text-4xl lg:text-5xl font-bold mb-6">{article.title}</h1>
            <div className="flex flex-wrap items-center gap-6 text-blue-100">
              <div className="flex items-center">
                <User className="w-5 h-5 mr-2" />
                {article.author}
              </div>
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2" />
                {new Date(article.date).toLocaleDateString('fr-FR', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </div>
              <div className="flex items-center">
                <Clock className="w-5 h-5 mr-2" />
                {article.readTime} de lecture
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Article Image */}
      <div className="container mx-auto px-4 -mt-16">
        <div className="max-w-4xl mx-auto">
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-[400px] object-cover rounded-xl shadow-lg"
          />
        </div>
      </div>

      {/* Article Content */}
      <main className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
              {/* Social Share */}
              <div className="flex items-center space-x-4 mb-8 pb-8 border-b border-gray-200">
                <span className="font-medium text-gray-700 flex items-center">
                  <Share2 className="w-5 h-5 mr-2" />
                  Partager
                </span>
                <div className="flex space-x-2">
                  <button className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors">
                    <Facebook className="w-5 h-5" />
                  </button>
                  <button className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors">
                    <Twitter className="w-5 h-5" />
                  </button>
                  <button className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors">
                    <Linkedin className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {/* Article Content */}
              <div
                className="prose prose-lg max-w-none"
                dangerouslySetInnerHTML={{ __html: article.content }}
              />
            </div>

            {/* Author Bio */}
            <div className="bg-white rounded-xl shadow-lg p-8 mb-16">
              <div className="flex items-center">
                <div className="image-placeholder w-16 h-16 rounded-full mr-6"></div>
                <div>
                  <h3 className="font-bold text-lg mb-2">{article.author}</h3>
                  <p className="text-gray-600">
                    Expert en géomatique et systèmes d'information géographique avec plus de 10 ans
                    d'expérience.
                  </p>
                </div>
              </div>
            </div>

            {/* Recommended Posts */}
            <div>
              <h2 className="text-2xl font-bold mb-8">Articles recommandés</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {recommendedPosts.map(post => (
                  <a
                    key={post.id}
                    href={`/blog/${post.id}`}
                    className="group bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
                  >
                    <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
                    <div className="p-6">
                      <span className="text-sm font-medium text-blue-600 mb-2 block">
                        {post.category}
                      </span>
                      <h3 className="text-xl font-bold mb-2 group-hover:text-blue-600 transition-colors">
                        {post.title}
                      </h3>
                      <p className="text-gray-600">{post.excerpt}</p>
                    </div>
                  </a>
                ))}
              </div>
            </div>

            {/* Navigation Buttons */}
            <div className="flex justify-between items-center mt-16">
              <a
                href="/blog"
                className="inline-flex items-center text-gray-600 hover:text-blue-600 transition-colors group"
              >
                <ArrowLeft className="w-5 h-5 mr-2 group-hover:-translate-x-1 transition-transform" />
                Retour aux articles
              </a>
              <div className="flex space-x-4">
                <button className="px-4 py-2 border border-gray-300 rounded-lg text-gray-600 hover:border-blue-600 hover:text-blue-600 transition-colors flex items-center">
                  <ArrowLeft className="w-4 h-4 mr-2" />
                  Article précédent
                </button>
                <button className="px-4 py-2 border border-gray-300 rounded-lg text-gray-600 hover:border-blue-600 hover:text-blue-600 transition-colors flex items-center">
                  Article suivant
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
