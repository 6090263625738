import React from 'react';
import { MapPin, Phone, Mail, Facebook, Linkedin, Twitter } from 'lucide-react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-blue-900 text-white">
      <div className="container mx-auto px-4">
        {/* Section principale */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 py-16">
          {/* À propos */}
          <div>
            <h3 className="text-xl font-bold mb-6">QADIR Experts</h3>
            <p className="text-blue-100 mb-6">
              Votre partenaire pour un développement territorial durable et intelligent.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-blue-300 transition-colors">
                <Facebook className="w-6 h-6" />
              </a>
              <a href="#" className="hover:text-blue-300 transition-colors">
                <Linkedin className="w-6 h-6" />
              </a>
              <a href="#" className="hover:text-blue-300 transition-colors">
                <Twitter className="w-6 h-6" />
              </a>
            </div>
          </div>

          {/* Services */}
          <div>
            <h3 className="text-xl font-bold mb-6">Nos Services</h3>
            <ul className="space-y-3">
              <li>
                <a
                  href="/services/urbanisme"
                  className="text-blue-100 hover:text-white transition-colors"
                >
                  Urbanisme & Aménagement
                </a>
              </li>
              <li>
                <a
                  href="/services/geomatique"
                  className="text-blue-100 hover:text-white transition-colors"
                >
                  Géomatique & Intelligence Territoriale
                </a>
              </li>
              <li>
                <a
                  href="/services/foncier"
                  className="text-blue-100 hover:text-white transition-colors"
                >
                  Foncier & Immobilier
                </a>
              </li>
              <li>
                <a
                  href="/services/numerique"
                  className="text-blue-100 hover:text-white transition-colors"
                >
                  Solutions Numériques
                </a>
              </li>
            </ul>
          </div>

          {/* Liens rapides */}
          <div>
            <h3 className="text-xl font-bold mb-6">Liens Rapides</h3>
            <ul className="space-y-3">
              <li>
                <a href="/about" className="text-blue-100 hover:text-white transition-colors">
                  À propos
                </a>
              </li>
              <li>
                <a href="/blog" className="text-blue-100 hover:text-white transition-colors">
                  Blog
                </a>
              </li>
              <li>
                <a href="/contact" className="text-blue-100 hover:text-white transition-colors">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-xl font-bold mb-6">Contact</h3>
            <ul className="space-y-4">
              <li className="flex items-center">
                <MapPin className="w-5 h-5 mr-3 text-blue-300" />
                <span className="text-blue-100">Abidjan, Côte d'Ivoire</span>
              </li>
              <li className="flex items-center">
                <Phone className="w-5 h-5 mr-3 text-blue-300" />
                <span className="text-blue-100">+225 01 43 43 05 05</span>
              </li>
              <li className="flex items-center">
                <Mail className="w-5 h-5 mr-3 text-blue-300" />
                <span className="text-blue-100">contact@qadir-experts.com</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Newsletter */}
        <div className="border-t border-blue-800 py-8">
          <div className="max-w-2xl mx-auto text-center">
            <h3 className="text-xl font-bold mb-4">Abonnez-vous à notre newsletter</h3>
            <p className="text-blue-100 mb-6">
              Restez informé de nos dernières actualités et innovations.
            </p>
            <form className="flex space-x-2">
              <input
                type="email"
                placeholder="Votre adresse email"
                className="flex-1 px-4 py-2 rounded-lg bg-blue-800 text-white placeholder-blue-300 border border-blue-700 focus:outline-none focus:border-blue-500"
              />
              <button
                type="submit"
                className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
              >
                S'abonner
              </button>
            </form>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-blue-800 py-6 text-center">
          <p className="text-blue-200">© {currentYear} QADIR Experts. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
}
