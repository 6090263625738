import React from 'react';
import {
  MapPin,
  Target,
  CheckCircle,
  ArrowUpRight,
  Clock,
  Users,
  File,
  ArrowRight,
} from 'lucide-react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

export default function ServiceDetail() {
  // Exemple avec le service Urbanisme & Aménagement
  const service = {
    id: 'urbanisme',
    title: 'Urbanisme & Aménagement',
    description:
      "Solutions complètes pour l'aménagement urbain et le développement territorial durable",
    icon: <MapPin className="w-12 h-12 text-white" />,
    color: 'from-blue-600 to-blue-400',
    features: [
      'Diagnostic territorial approfondi',
      'Planification stratégique',
      "Conception d'espaces publics",
      'Gestion des risques',
      'Revitalisation urbaine',
      'Opérations immobilières',
    ],
    process: [
      {
        title: 'Analyse initiale',
        description: 'Évaluation complète des besoins et contraintes du territoire',
      },
      {
        title: 'Conception',
        description: 'Élaboration des solutions et stratégies adaptées',
      },
      {
        title: 'Planification',
        description: 'Organisation détaillée des interventions',
      },
      {
        title: 'Réalisation',
        description: 'Mise en œuvre des solutions proposées',
      },
    ],
    benefits: [
      "Optimisation de l'espace urbain",
      'Développement durable intégré',
      'Amélioration du cadre de vie',
      'Solutions innovantes',
      'Respect des normes environnementales',
      'Valorisation du patrimoine',
    ],
    projects: [
      {
        title: 'Revitalisation du centre-ville',
        description: 'Projet de réaménagement urbain pour dynamiser le cœur de ville',
        image: <div className="image-placeholder aspect-w-4 aspect-h-3 rounded-lg"></div>,
      },
      {
        title: 'Quartier écologique',
        description: "Conception d'un nouveau quartier respectueux de l'environnement",
        image: <div className="image-placeholder aspect-w-4 aspect-h-3 rounded-lg"></div>,
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="relative bg-blue-900 py-20 text-white">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-800 to-blue-900 opacity-90"></div>
        <div className="image-placeholder w-full h-full"></div>
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl">
            <div className={`w-20 h-20 rounded-2xl bg-gradient-to-r ${service.color} p-4 mb-8`}>
              {service.icon}
            </div>
            <h1 className="text-5xl font-bold mb-6">{service.title}</h1>
            <p className="text-xl text-blue-100">{service.description}</p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-16">
            <div className="lg:col-span-2">
              <h2 className="text-3xl font-bold mb-8">Nos prestations</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {service.features.map((feature, index) => (
                  <div key={index} className="flex items-start bg-white p-6 rounded-xl shadow-lg">
                    <CheckCircle className="w-6 h-6 text-green-500 mr-4 flex-shrink-0" />
                    <span className="text-gray-700">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-bold mb-6">Pourquoi nous choisir ?</h3>
              <div className="space-y-6">
                <div className="flex items-center">
                  <div className="bg-blue-100 p-3 rounded-full mr-4">
                    <Target className="w-6 h-6 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-bold">Expertise reconnue</h4>
                    <p className="text-gray-600">Plus de 10 ans d'expérience</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="bg-blue-100 p-3 rounded-full mr-4">
                    <Clock className="w-6 h-6 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-bold">Réactivité</h4>
                    <p className="text-gray-600">Suivi régulier et proximité</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="bg-blue-100 p-3 rounded-full mr-4">
                    <Users className="w-6 h-6 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-bold">Équipe qualifiée</h4>
                    <p className="text-gray-600">Experts pluridisciplinaires</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Notre processus</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {service.process.map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-blue-100 rounded-xl p-6">
                  <span className="absolute -top-4 left-4 bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
                    {index + 1}
                  </span>
                  <h3 className="font-bold text-xl mb-3 mt-2">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                {index < service.process.length - 1 && (
                  <ArrowRight className="hidden lg:block absolute top-1/2 -right-6 w-6 h-6 text-blue-300 transform -translate-y-1/2" />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12">Projets réalisés</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {service.projects.map((project, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden group">
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <button className="text-blue-600 font-medium flex items-center group">
                    En savoir plus
                    <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="bg-gradient-to-r from-blue-900 to-blue-800 py-20 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Prêt à démarrer votre projet ?</h2>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Contactez-nous pour discuter de vos besoins et découvrir comment nous pouvons vous
            aider.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="/contact"
              className="inline-flex items-center bg-white text-blue-900 px-8 py-4 rounded-full font-medium hover:bg-gray-100 transition-colors"
            >
              Nous contacter
              <ArrowUpRight className="ml-2 w-5 h-5" />
            </a>
            <a
              href="#"
              className="inline-flex items-center border-2 border-white text-white px-8 py-4 rounded-full font-medium hover:bg-white/10 transition-colors"
            >
              <File className="w-5 h-5 mr-2" />
              Télécharger la brochure
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
