import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import BlogPage from './pages/BlogPage';
import BlogPost from './pages/BlogPost';
import ServiceUrbanisme from './pages/services/ServiceUrbanisme';
import ServiceGeomatique from './pages/services/ServiceGeomatique';
import ServiceFoncier from './pages/services/ServiceFoncier';
import ServiceNumerique from './pages/services/ServiceNumerique';
import ServicesPage from './pages/ServicesPage'; // Importez le composant ServicesPage

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog/:id" element={<BlogPost />} />
      <Route path="/services" element={<ServicesPage />} /> {/* Ajoutez cette ligne */}
      <Route path="/services/urbanisme" element={<ServiceUrbanisme />} />
      <Route path="/services/geomatique" element={<ServiceGeomatique />} />
      <Route path="/services/foncier" element={<ServiceFoncier />} />
      <Route path="/services/numerique" element={<ServiceNumerique />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

// Composant 404
const NotFound = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
    <div className="max-w-md text-center">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">Page non trouvée</h1>
      <p className="text-gray-600 mb-8">
        Désolé, la page que vous recherchez n'existe pas ou a été déplacée.
      </p>
      <a
        href="/"
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
      >
        Retour à l'accueil
      </a>
    </div>
  </div>
);

export default AppRoutes;
