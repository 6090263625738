import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, ChevronDown, ChevronUp } from 'lucide-react';

export default function Navigation() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { label: 'Accueil', href: '/' },
    { label: 'Services', href: '/services' },
    { label: 'À propos', href: '/about' },
    // { label: 'Blog', href: '/blog' },
    { label: 'Contact', href: '/contact' },
  ];

  const serviceItems = [
    { label: 'Urbanisme & Aménagement', href: '/services/urbanisme' },
    {
      label: 'Géomatique & Intelligence Territoriale',
      href: '/services/geomatique',
    },
    { label: 'Foncier & Immobilier', href: '/services/foncier' },
    { label: 'Solutions Numériques', href: '/services/numerique' },
  ];

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled ? 'bg-white shadow-lg py-2' : 'bg-transparent py-4'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/">
            <img
              src={scrolled ? '/logo-color.png' : '/logo-white.png'}
              alt="QADIR Experts Logo"
              className="h-14 w-auto"
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navItems.map(item =>
              item.label === 'Services' ? (
                <div key={item.label} className="relative flex items-center">
                  <button
                    onClick={() => navigate('/services')}
                    className={`font-medium hover:opacity-75 transition-opacity ${
                      scrolled ? 'text-blue-900' : 'text-white'
                    } ${location.pathname.startsWith('/services') ? 'border-b-2 border-current' : ''}`}
                  >
                    {item.label}
                  </button>
                  <button
                    onClick={() => setServicesMenuOpen(!servicesMenuOpen)}
                    className={`ml-1 ${scrolled ? 'text-blue-900' : 'text-white'}`}
                  >
                    {servicesMenuOpen ? (
                      <ChevronUp className="w-4 h-4" />
                    ) : (
                      <ChevronDown className="w-4 h-4" />
                    )}
                  </button>
                  {servicesMenuOpen && (
                    <div className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-lg">
                      {serviceItems.map(subItem => (
                        <Link
                          key={subItem.label}
                          to={subItem.href}
                          className="block px-4 py-2 text-blue-900 hover:bg-gray-100"
                          onClick={() => setServicesMenuOpen(false)}
                        >
                          {subItem.label}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={item.label}
                  to={item.href}
                  className={`font-medium hover:opacity-75 transition-opacity ${
                    scrolled ? 'text-blue-900' : 'text-white'
                  } ${location.pathname === item.href ? 'border-b-2 border-current' : ''}`}
                >
                  {item.label}
                </Link>
              )
            )}
            <Link
              to="/contact"
              className={`px-6 py-2 rounded-full transition-colors ${
                scrolled
                  ? 'bg-blue-900 text-white hover:bg-blue-800'
                  : 'bg-white text-blue-900 hover:bg-gray-100'
              }`}
            >
              Nous contacter
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button className="md:hidden" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            {mobileMenuOpen ? (
              <X className={scrolled ? 'text-blue-900' : 'text-white'} />
            ) : (
              <Menu className={scrolled ? 'text-blue-900' : 'text-white'} />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div className="md:hidden absolute top-full left-0 w-full bg-white shadow-lg py-4">
            <div className="container mx-auto px-4">
              <div className="flex flex-col space-y-4">
                {navItems.map(item =>
                  item.label === 'Services' ? (
                    <div key={item.label} className="relative flex items-center">
                      <button
                        onClick={() => navigate('/services')}
                        className="text-blue-900 font-medium hover:text-blue-700 transition-colors"
                      >
                        {item.label}
                      </button>
                      <button
                        onClick={() => setServicesMenuOpen(!servicesMenuOpen)}
                        className="ml-1 text-blue-900"
                      >
                        {servicesMenuOpen ? (
                          <ChevronUp className="w-4 h-4" />
                        ) : (
                          <ChevronDown className="w-4 h-4" />
                        )}
                      </button>
                      {servicesMenuOpen && (
                        <div className="mt-2">
                          {serviceItems.map(subItem => (
                            <Link
                              key={subItem.label}
                              to={subItem.href}
                              className="block px-4 py-2 text-blue-900 hover:bg-gray-100"
                              onClick={() => {
                                setServicesMenuOpen(false);
                                setMobileMenuOpen(false);
                              }}
                            >
                              {subItem.label}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      key={item.label}
                      to={item.href}
                      className="text-blue-900 font-medium hover:text-blue-700 transition-colors"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.label}
                    </Link>
                  )
                )}
                <Link
                  to="/contact"
                  className="bg-blue-900 text-white px-6 py-2 rounded-full text-center font-medium hover:bg-blue-800 transition-colors"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Nous contacter
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}
