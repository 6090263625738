import React, { useState } from 'react';
import { Search, Tag, Calendar, User, ArrowRight, Mail } from 'lucide-react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default function BlogPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  // Articles de blog (simulation de données)
  const articles = [
    {
      id: 1,
      title: "L'importance de la géomatique dans l'aménagement urbain moderne",
      excerpt:
        'Découvrez comment les technologies géospatiales révolutionnent la planification urbaine...',
      category: 'geomatique',
      date: '2024-03-15',
      author: 'Jean Dupont',
      image: <div className="image-placeholder aspect-w-16 aspect-h-9 rounded-xl"></div>,
      readTime: '5 min',
    },
    {
      id: 2,
      title: 'Sécurisation foncière : enjeux et solutions en Afrique',
      excerpt: 'Analyse des défis actuels et des approches innovantes pour la gestion foncière...',
      category: 'foncier',
      date: '2024-03-10',
      author: 'Marie Koné',
      image: <div className="image-placeholder aspect-w-16 aspect-h-9 rounded-xl"></div>,
      readTime: '8 min',
    },
    {
      id: 3,
      title: "Smart Cities : l'avenir de l'urbanisme intelligent",
      excerpt: 'Les nouvelles technologies au service du développement urbain durable...',
      category: 'urbanisme',
      date: '2024-03-05',
      author: 'Paul Kouassi',
      image: <div className="image-placeholder aspect-w-16 aspect-h-9 rounded-xl"></div>,
      readTime: '6 min',
    },
    {
      id: 4,
      title: 'Innovation numérique dans la gestion territoriale',
      excerpt: "Comment le digital transforme la gestion et l'analyse des territoires...",
      category: 'numerique',
      date: '2024-03-01',
      author: 'Sophie Martin',
      image: <div className="image-placeholder aspect-w-16 aspect-h-9 rounded-xl"></div>,
      readTime: '7 min',
    },
  ];

  const categories = [
    { id: 'all', name: 'Tous les articles', color: 'blue' },
    { id: 'urbanisme', name: 'Urbanisme', color: 'green' },
    { id: 'geomatique', name: 'Géomatique', color: 'purple' },
    { id: 'foncier', name: 'Foncier', color: 'orange' },
    { id: 'numerique', name: 'Numérique', color: 'pink' },
  ];

  const filteredArticles = articles.filter(article => {
    const matchesSearch =
      article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || article.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="bg-blue-900 py-20 text-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-bold mb-6">Blog & Actualités</h1>
            <p className="text-xl text-blue-100">
              Découvrez nos derniers articles, analyses et actualités sur l'urbanisme, la géomatique
              et l'innovation territoriale
            </p>
          </div>
        </div>
      </section>

      {/* Search and Filter Section */}
      <section className="py-8 bg-white shadow-md">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="relative w-full md:w-96">
              <input
                type="text"
                placeholder="Rechercher un article..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              />
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>
            <div className="flex flex-wrap gap-2">
              {categories.map(category => (
                <button
                  key={category.id}
                  onClick={() => setSelectedCategory(category.id)}
                  className={`px-4 py-2 rounded-full transition-colors ${
                    selectedCategory === category.id
                      ? 'bg-blue-900 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Articles Grid */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredArticles.map(article => (
              <article
                key={article.id}
                className="bg-white rounded-2xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
              >
                <div className="relative">
                  <img
                    src={article.image}
                    alt={article.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute top-4 left-4">
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-medium bg-${
                        categories.find(cat => cat.id === article.category)?.color
                      }-100 text-${categories.find(cat => cat.id === article.category)?.color}-800`}
                    >
                      {categories.find(cat => cat.id === article.category)?.name}
                    </span>
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center space-x-4 text-sm text-gray-500 mb-4">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-2" />
                      {new Date(article.date).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </div>
                    <div className="flex items-center">
                      <User className="w-4 h-4 mr-2" />
                      {article.author}
                    </div>
                  </div>
                  <h2 className="text-xl font-bold mb-3 hover:text-blue-600 transition-colors">
                    <a href={`/blog/${article.id}`}>{article.title}</a>
                  </h2>
                  <p className="text-gray-600 mb-4">{article.excerpt}</p>
                  <a
                    href={`/blog/${article.id}`}
                    className="inline-flex items-center text-blue-600 font-medium hover:text-blue-800 transition-colors group"
                  >
                    Lire la suite
                    <ArrowRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
                  </a>
                </div>
              </article>
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="bg-blue-900 py-16 text-white">
        <div className="container mx-auto px-4 text-center">
          <div className="max-w-2xl mx-auto">
            <h2 className="text-3xl font-bold mb-6">Restez informé</h2>
            <p className="text-blue-100 mb-8">
              Abonnez-vous à notre newsletter pour recevoir nos derniers articles et actualités
            </p>
            <form className="flex flex-col sm:flex-row gap-4">
              <div className="flex-1 relative">
                <Mail className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="email"
                  placeholder="Votre adresse email"
                  className="w-full pl-12 pr-4 py-3 rounded-lg text-gray-900"
                />
              </div>
              <button
                type="submit"
                className="bg-orange-500 hover:bg-orange-600 text-white px-8 py-3 rounded-lg font-medium transition-colors"
              >
                S'abonner
              </button>
            </form>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
