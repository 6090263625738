import React from 'react';
import { Users, Target, Award, Rocket, CheckCircle, ArrowUpRight } from 'lucide-react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default function AboutPage() {
  const stats = [
    { number: '05+', label: "Ans d'expérience" },
    { number: '50+', label: 'Projets réalisés' },
    { number: '20+', label: 'Experts qualifiés' },
    { number: '95%', label: 'Clients satisfaits' },
  ];

  const values = [
    {
      icon: <Award className="w-8 h-8 text-blue-600" />,
      title: 'Excellence',
      description: "Nous visons l'excellence dans chaque projet et chaque interaction.",
    },
    {
      icon: <Target className="w-8 h-8 text-orange-600" />,
      title: 'Innovation',
      description: 'Adoption des dernières technologies pour des solutions optimales.',
    },
    {
      icon: <Users className="w-8 h-8 text-green-600" />,
      title: 'Collaboration',
      description: "Travail d'équipe étroit avec nos clients et partenaires.",
    },
    {
      icon: <Rocket className="w-8 h-8 text-purple-600" />,
      title: 'Performance',
      description: 'Résultats mesurables et impact positif sur les territoires.',
    },
  ];

  const team = [
    {
      name: 'Lavoisier Ousmane',
      role: 'Géomaticien & Urbaniste',
      image: <div className="placeholder-image aspect-w-1 aspect-h-1"></div>,
      description: "Expert en Développement Foncier et Immobilier avec plus de 8 ans d'expérience.",
    },
    {
      name: 'Abdallah Traoré',
      role: 'Ingénieur Logiciel',
      image: (
        <div className="bg-gradient-to-br from-gray-200 to-gray-300 aspect-w-1 aspect-h-1"></div>
      ),
      description: "Plus de 10 ans dans le développement d'applications Web/Mobile.",
    },
    {
      name: 'Doukouré Ben',
      role: 'Géomaticien',
      image: <div className="placeholder-image aspect-w-1 aspect-h-1"></div>,
      description:
        "Spécialiste en SIG et Téledetection avec plus de 15 ans dans le traitement d'images.",
    },
    {
      name: 'Aziz Tiémoko T',
      role: 'Financier & Comptable',
      image: (
        <div className="bg-gradient-to-br from-gray-200 to-gray-300 aspect-w-1 aspect-h-1"></div>
      ),
      description: 'Spécialiste en ingénierie financière.',
    },
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navigation />

      {/* Hero Section */}
      <section className="relative bg-blue-900 py-20 text-white">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-800 to-blue-900"></div>
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-bold mb-6">À Propos de QADIR Experts</h1>
            <p className="text-xl text-blue-100">
              Une expertise reconnue au service du développement territorial durable
            </p>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Notre Mission</h2>
              <p className="text-gray-600 text-lg mb-8">
                QADIR Experts est une entreprise interdisciplinaire spécialisée dans l'urbanisme, la
                géomatique, et le foncier. Notre mission est de fournir des solutions durables et
                intelligentes qui allient innovation technologique et expertise locale.
              </p>
              <div className="space-y-4">
                {[
                  'Solutions innovantes pour le développement territorial',
                  'Expertise technique de haut niveau',
                  'Approche durable et responsable',
                  "Engagement pour l'excellence",
                ].map((item, index) => (
                  <div key={index} className="flex items-center">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-3" />
                    <span className="text-gray-700">{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-gray-100 rounded-2xl p-8">
              <div className="image-placeholder aspect-w-3 aspect-h-2 rounded-xl w-full"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-blue-900 py-20 text-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-bold mb-2">{stat.number}</div>
                <div className="text-blue-200">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Nos Valeurs</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="mb-4">{value.icon}</div>
                <h3 className="text-xl font-bold mb-3">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Notre Équipe</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {team.map((member, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden group">
                <div className="aspect-w-4 aspect-h-3">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                  <p className="text-blue-600 font-medium mb-3">{member.role}</p>
                  <p className="text-gray-600">{member.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-blue-900 to-blue-800 py-20">
        <div className="container mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-6">Prêt à collaborer avec notre équipe ?</h2>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Découvrez comment notre expertise peut transformer vos projets en réalité.
          </p>
          <a
            href="/contact"
            className="inline-flex items-center bg-white text-blue-900 px-8 py-4 rounded-full font-medium hover:bg-gray-100 transition-colors group"
          >
            Contactez-nous
            <ArrowUpRight className="ml-2 w-5 h-5 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
          </a>
        </div>
      </section>

      <Footer />
    </div>
  );
}
