import React from 'react';
import {
  Building,
  Target,
  CheckCircle,
  ArrowUpRight,
  Clock,
  Users,
  File,
  ArrowRight,
  Key,
  LineChart,
  Shield,
} from 'lucide-react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

export default function FoncierService() {
  const service = {
    id: 'foncier',
    title: 'Foncier & Immobilier',
    description:
      'Solutions expertes pour la sécurisation foncière et la gestion immobilière optimale de vos projets',
    icon: <Building className="w-12 h-12 text-white" />,
    color: 'from-orange-600 to-orange-400',
    features: [
      'Analyse et diagnostic foncier',
      'Sécurisation des titres fonciers',
      'Gestion des terres rurales et urbaines',
      'Conseil en acquisition',
      'Évaluation immobilière',
      'Gestion de patrimoine foncier',
    ],
    process: [
      {
        title: 'Diagnostic initial',
        description: 'Évaluation complète de la situation foncière',
      },
      {
        title: 'Stratégie',
        description: "Élaboration du plan d'action personnalisé",
      },
      {
        title: 'Sécurisation',
        description: 'Mise en œuvre des procédures de sécurisation',
      },
      {
        title: 'Suivi',
        description: 'Gestion continue et actualisation',
      },
    ],
    benefits: [
      'Sécurité juridique renforcée',
      'Optimisation des investissements',
      'Gestion transparente',
      'Conformité réglementaire',
      'Valorisation patrimoniale',
      'Accompagnement personnalisé',
    ],
    expertises: [
      {
        icon: <Key />,
        title: 'Sécurisation foncière',
        description: 'Protection juridique et administrative de vos biens',
      },
      {
        icon: <LineChart />,
        title: 'Valorisation immobilière',
        description: "Stratégies d'optimisation de votre patrimoine",
      },
      {
        icon: <Shield />,
        title: 'Conformité légale',
        description: 'Respect des normes et réglementations en vigueur',
      },
    ],
    projects: [
      {
        title: 'Régularisation foncière urbaine',
        description: 'Programme de sécurisation foncière pour un quartier résidentiel',
        image: <div className="image-placeholder aspect-w-4 aspect-h-3 rounded-lg"></div>,
        results: ['500 titres régularisés', '2500 bénéficiaires', '100 hectares sécurisés'],
      },
      {
        title: 'Gestion de patrimoine agricole',
        description: "Mise en place d'un système de gestion foncière rurale",
        image: <div className="image-placeholder aspect-w-4 aspect-h-3 rounded-lg"></div>,
        results: ['1000 hectares gérés', 'Système SIG intégré', 'Revenus optimisés +25%'],
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="relative bg-orange-900 py-20 text-white">
        <div className="absolute inset-0 bg-gradient-to-r from-orange-900 via-orange-800 to-orange-900 opacity-90"></div>
        <div className="absolute inset-0">
          <div className="image-placeholder w-full h-full"></div>
        </div>
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl">
            <div className={`w-20 h-20 rounded-2xl bg-gradient-to-r ${service.color} p-4 mb-8`}>
              {service.icon}
            </div>
            <h1 className="text-5xl font-bold mb-6">{service.title}</h1>
            <p className="text-xl text-orange-100">{service.description}</p>
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section className="py-20 -mt-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {service.expertises.map((expertise, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className={`w-16 h-16 rounded-xl bg-gradient-to-r ${service.color} p-4 mb-6`}>
                  <div className="text-white w-full h-full">{expertise.icon}</div>
                </div>
                <h3 className="text-xl font-bold mb-3">{expertise.title}</h3>
                <p className="text-gray-600">{expertise.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-16">
            <div className="lg:col-span-2">
              <h2 className="text-3xl font-bold mb-8">Nos services fonciers</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {service.features.map((feature, index) => (
                  <div key={index} className="flex items-start bg-white p-6 rounded-xl shadow-lg">
                    <CheckCircle className="w-6 h-6 text-orange-500 mr-4 flex-shrink-0" />
                    <span className="text-gray-700">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-bold mb-6">Nos garanties</h3>
              <div className="space-y-6">
                <div className="flex items-center">
                  <div className="bg-orange-100 p-3 rounded-full mr-4">
                    <Target className="w-6 h-6 text-orange-600" />
                  </div>
                  <div>
                    <h4 className="font-bold">Expertise juridique</h4>
                    <p className="text-gray-600">Conformité et sécurité garanties</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="bg-orange-100 p-3 rounded-full mr-4">
                    <Clock className="w-6 h-6 text-orange-600" />
                  </div>
                  <div>
                    <h4 className="font-bold">Suivi continu</h4>
                    <p className="text-gray-600">Accompagnement permanent</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="bg-orange-100 p-3 rounded-full mr-4">
                    <Users className="w-6 h-6 text-orange-600" />
                  </div>
                  <div>
                    <h4 className="font-bold">Équipe dédiée</h4>
                    <p className="text-gray-600">Experts fonciers qualifiés</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Notre méthodologie</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {service.process.map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-orange-100 rounded-xl p-6">
                  <span className="absolute -top-4 left-4 bg-orange-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
                    {index + 1}
                  </span>
                  <h3 className="font-bold text-xl mb-3 mt-2">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                {index < service.process.length - 1 && (
                  <ArrowRight className="hidden lg:block absolute top-1/2 -right-6 w-6 h-6 text-orange-300 transform -translate-y-1/2" />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12">Projets réalisés</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {service.projects.map((project, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden group">
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <div className="space-y-2 mb-4">
                    {project.results.map((result, resultIndex) => (
                      <div key={resultIndex} className="flex items-center text-green-600">
                        <CheckCircle className="w-4 h-4 mr-2" />
                        <span>{result}</span>
                      </div>
                    ))}
                  </div>
                  <button className="text-orange-600 font-medium flex items-center group">
                    Voir le détail
                    <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="bg-gradient-to-r from-orange-900 to-orange-800 py-20 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Un projet foncier ou immobilier ?</h2>
          <p className="text-xl text-orange-100 mb-8 max-w-2xl mx-auto">
            Nos experts sont à votre disposition pour sécuriser et optimiser votre patrimoine
            foncier.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="/contact"
              className="inline-flex items-center bg-white text-orange-900 px-8 py-4 rounded-full font-medium hover:bg-gray-100 transition-colors"
            >
              Discuter de votre projet
              <ArrowUpRight className="ml-2 w-5 h-5" />
            </a>
            <a
              href="#"
              className="inline-flex items-center border-2 border-white text-white px-8 py-4 rounded-full font-medium hover:bg-white/10 transition-colors"
            >
              <File className="w-5 h-5 mr-2" />
              Guide foncier
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
